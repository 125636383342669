.navbar-custom {
    z-index: 9999;
    background-color: #f9c338;
  }

#responsive-navbar-nav{
    background-color: #f9c338;
}

.rounded-circle{
  height: 50px;
  width: 50px
}